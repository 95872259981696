export const CITIES = {
  'all': {
    id: 'all',
    name: 'Все города',
    nameInCase: 'Все города',
    image: 'https://images.unsplash.com/photo-1537996194471-e657df975ab4',
    coordinates: [-8.409518, 115.188919]
  },
  'moscow': {
    id: 'moscow',
    name: 'Москва',
    nameInCase: 'Москва',
    image: 'https://images.unsplash.com/photo-1513326738677-b964603b136d',
    coordinates: [55.755819, 37.617644]
  },
  'spb': {
    id: 'spb',
    name: 'Санкт-Петербург',
    nameInCase: 'Санкт-Петербург',
    image: 'https://images.unsplash.com/photo-1556610961-2fecc5927173',
    coordinates: [59.938951, 30.315635]
  },
  'kaliningrad': {
    id: 'kaliningrad',
    name: 'Калининград',
    nameInCase: 'Калининград',
    image: 'https://images.unsplash.com/photo-1568114775080-4d00d72d4d51',
    coordinates: [54.710162, 20.510137]
  },
  'sochi': {
    id: 'sochi',
    name: 'Сочи',
    nameInCase: 'Сочи',
    image: 'https://images.unsplash.com/photo-1549092156-04f2525c3498',
    coordinates: [43.585472, 39.723098]
  },
  'bali': {
    id: 'bali',
    name: 'Бали',
    nameInCase: 'Бали',
    image: 'https://images.unsplash.com/photo-1537996194471-e657df975ab4',
    coordinates: [-8.409518, 115.188919]
  },
};

export const PROPERTY_TYPES = {
  'all': {
    id: 'all',
    name: 'Вся недвижимость',
    icon: 'building-columns'
  },
  'new': {
    id: 'new',
    name: 'Новостройки',
    icon: 'building',
    description: 'Квартиры в новых жилых комплексах'
  },
  'secondary': {
    id: 'secondary',
    name: 'Вторичка',
    icon: 'home',
    description: 'Готовые квартиры для проживания'
  },
  'house': {
    id: 'house',
    name: 'Дома',
    icon: 'house',
    description: 'Частные дома и коттеджи'
  },
  'commercial': {
    id: 'commercial',
    name: 'Коммерческая',
    icon: 'store',
    description: 'Офисы, магазины, склады'
  }
};

export const ROOM_TYPES = [
  { id: 'studio', name: 'Студия' },
  { id: '1', name: '1' },
  { id: '2', name: '2' },
  { id: '3', name: '3' },
  { id: '4plus', name: '4+' }
];

// Функции-помощники
export const getCityName = (cityId, inCase = false) => {
  const city = CITIES[cityId] || CITIES.all;
  return inCase ? city.nameInCase : city.name;
};

export const getPropertyTypeName = (typeId) => {
  const type = PROPERTY_TYPES[typeId] || PROPERTY_TYPES.all;
  return type.name;
};

// Популярные направления для главной страницы
export const POPULAR_DESTINATIONS = [
  {
    cityId: 'moscow',
    typeId: 'new',
    title: 'Новостройки в Москве',
    image: CITIES.moscow.image
  },
  {
    cityId: 'spb',
    typeId: 'secondary',
    title: 'Вторичка в Санкт-Петербурге',
    image: CITIES.spb.image
  },
  {
    cityId: 'kaliningrad',
    typeId: 'house',
    title: 'Дома в Калининграде',
    image: CITIES.kaliningrad.image
  },
  {
    cityId: 'sochi',
    typeId: 'new',
    title: 'Новостройки в Сочи',
    image: CITIES.sochi.image
  }
];

// Настройки фильтров по умолчанию
export const DEFAULT_FILTERS = {
  priceMin: '',
  priceMax: '',
  areaMin: '',
  areaMax: '',
  rooms: [],
  city: 'all',
  type: 'all'
};

export const STATISTICS = {
  cities: Object.keys(CITIES).length - 1, // Минус 'all'
  propertyTypes: Object.keys(PROPERTY_TYPES).length - 1, // Минус 'all'
  clients: 500,
  properties: 2000
}; 