import { useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import SEO from '../../components/SEO';
import UserProfile from './components/UserProfile';
import ListingsTabs from './components/ListingsTabs';
import ListingsGrid from './components/ListingsGrid';
import ListingsFilters from './components/ListingsFilters';
import './styles.scss';

function Account() {
  const { user } = useAuth();
  const [activeTab, setActiveTab] = useState('active');
  const [searchQuery, setSearchQuery] = useState('');
  const [filters, setFilters] = useState({
    promotionServices: '',
    discountMailings: '',
    priceFrom: '',
    priceTo: '',
    location: '',
    autoPublish: '',
    deliveryDiscount: '',
    avitoDelivery: '',
    category: '',
    dateRange: '30',
    sortBy: 'date'
  });

  return (
    <div className="account-page">
      <SEO 
        title="Мои объявления"
        description="Управление объявлениями и настройками профиля"
      />
      
      <div className="account-container">
        <UserProfile 
          name={user?.name || "Пользователь"} 
          rating={4.5}
          reviews={8}
          earnings="148 260 ₽"
          views={{
            today: 0,
            period: 87,
            periodDays: 30
          }}
        />

        <ListingsTabs 
          activeTab={activeTab}
          onTabChange={setActiveTab}
          counts={{
            active: 0,
            withErrors: 0,
            unpublished: 0,
            archive: 32,
            deleted: 0,
            drafts: 0
          }}
        />

        <ListingsFilters 
          searchQuery={searchQuery}
          onSearchChange={setSearchQuery}
          filters={filters}
          onFiltersChange={setFilters}
        />

        <ListingsGrid 
          activeTab={activeTab}
          searchQuery={searchQuery}
          filters={filters}
        />
      </div>
    </div>
  );
}

export default Account; 