import styled from 'styled-components';

const Section = styled.section`
  padding: 60px 0;
  background: #111;

  @media (max-width: 768px) {
    padding: 40px 0;
  }
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 60px;
  align-items: center;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 40px;
  }
`;

const Content = styled.div`
  @media (max-width: 768px) {
    order: 2;
  }
`;

const Title = styled.h2`
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    font-size: 28px;
    margin-bottom: 20px;
  }
`;

const Description = styled.p`
  color: #888;
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    font-size: 16px;
    margin-bottom: 30px;
  }
`;

const Stats = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 20px;
  }
`;

const StatItem = styled.div`
  text-align: left;
  background: #141414;
  border: 1px solid #262626;
  border-radius: 8px;
  padding: 14px 24px 20px;
`;

const StatNumber = styled.div`
  font-size: 48px;
  font-weight: 700;
  color: var(--primary-color);
  margin-bottom: 4px;

  @media (max-width: 768px) {
    font-size: 36px;
  }
`;

const StatLabel = styled.div`
  color: #888;
  font-size: 16px;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const Image = styled.img`
  width: 100%;
  border-radius: 20px;

  @media (max-width: 768px) {
    order: 1;
    border-radius: 16px;
  }
`;

function AboutUs() {
  return (
    <Section>
      <Container>
        <Grid>
          <Content>
            <Title>О компании OverSeas</Title>
            <Description>
              Мы помогаем нашим клиентам найти идеальную недвижимость по всему миру. 
              Наша команда профессионалов работает с лучшими застройщиками и агентствами, 
              чтобы предоставить вам широкий выбор качественной недвижимости.
            </Description>
            <Stats>
              <StatItem>
                <StatNumber>67</StatNumber>
                <StatLabel>Партнёров</StatLabel>
              </StatItem>
              <StatItem>
                <StatNumber>400+</StatNumber>
                <StatLabel>Объектов</StatLabel>
              </StatItem>
              <StatItem>
                <StatNumber>13</StatNumber>
                <StatLabel>Городов</StatLabel>
              </StatItem>
            </Stats>
          </Content>
          <Image 
            src="https://images.unsplash.com/photo-1497366216548-37526070297c?ixlib=rb-4.0.3" 
            alt="About OverSeas" 
          />
        </Grid>
      </Container>
    </Section>
  );
}

export default AboutUs; 