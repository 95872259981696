import './styles.scss';

function ListingsTabs({ activeTab, onTabChange, counts }) {
  const tabs = [
    { id: 'active', name: 'Активные', count: counts.active },
    { id: 'withErrors', name: 'С ошибками', count: counts.withErrors },
    { id: 'unpublished', name: 'Неопубликованные', count: counts.unpublished },
    { id: 'archive', name: 'Архив', count: counts.archive },
    { id: 'deleted', name: 'Удалённые', count: counts.deleted },
    { id: 'drafts', name: 'Черновики', count: counts.drafts }
  ];

  return (
    <div className="listings-tabs">
      {tabs.map(tab => (
        <button
          key={tab.id}
          className={`tab ${activeTab === tab.id ? 'active' : ''}`}
          onClick={() => onTabChange(tab.id)}
        >
          {tab.name}
          <span className="count">{tab.count}</span>
        </button>
      ))}
    </div>
  );
}

export default ListingsTabs; 