import { useState } from 'react';
import SEO from '../../components/SEO';
import './styles.scss';

// Данные для настройки
const catalogData = {
  uae: {
    name: 'ОАЭ',
    image: 'https://images.unsplash.com/photo-1512453979798-5ea266f8880c?ixlib=rb-4.0.3',
    description: 'Инвестиции в недвижимость ОАЭ: от роскошных апартаментов до вилл премиум-класса',
    stats: {
      objects: '350+',
      developers: '25',
      avgRoi: '8.5%'
    },
    cities: [
      { 
        id: 'dubai', 
        name: 'Дубай',
        propertyCount: 245,
        priceFrom: '$280,000',
        types: ['Апартаменты', 'Виллы', 'Пентхаусы']
      },
      { 
        id: 'abu-dhabi', 
        name: 'Абу-Даби',
        propertyCount: 180,
        priceFrom: '$320,000',
        types: ['Апартаменты', 'Таунхаусы']
      }
    ]
  },
  russia: {
    name: 'Россия',
    image: 'https://images.unsplash.com/photo-1513326738677-b964603b136d?ixlib=rb-4.0.3',
    description: 'Недвижимость в крупнейших городах России: от московских небоскребов до курортных резиденций',
    stats: {
      objects: '500+',
      developers: '35',
      avgRoi: '6.5%'
    },
    cities: [
      { 
        id: 'moscow', 
        name: 'Москва',
        propertyCount: 320,
        priceFrom: '12,000,000 ₽',
        types: ['Апартаменты', 'Квартиры', 'Пентхаусы']
      },
      { 
        id: 'kaliningrad', 
        name: 'Калининград',
        propertyCount: 150,
        priceFrom: '6,000,000 ₽',
        types: ['Квартиры', 'Дома']
      },
      { 
        id: 'svetlogorsk', 
        name: 'Светлогорск',
        propertyCount: 85,
        priceFrom: '5,500,000 ₽',
        types: ['Апартаменты', 'Квартиры']
      }
    ]
  },
  thailand: {
    name: 'Таиланд',
    image: 'https://images.unsplash.com/photo-1552465011-b4e21bf6e79a?ixlib=rb-4.0.3',
    description: 'Инвестиции в курортную недвижимость Таиланда: от городских кондо до вилл на берегу моря',
    stats: {
      objects: '280+',
      developers: '20',
      avgRoi: '7.5%'
    },
    cities: [
      { 
        id: 'phuket', 
        name: 'Пхукет',
        propertyCount: 165,
        priceFrom: '$150,000',
        types: ['Виллы', 'Кондо', 'Апартаменты']
      },
      { 
        id: 'pattaya', 
        name: 'Паттайя',
        propertyCount: 210,
        priceFrom: '$85,000',
        types: ['Кондо', 'Апартаменты']
      }
    ]
  },
  indonesia: {
    name: 'Индонезия',
    image: 'https://images.unsplash.com/photo-1537996194471-e657df975ab4?ixlib=rb-4.0.3',
    description: 'Недвижимость на райских островах Индонезии: инвестиции в туристический бизнес',
    stats: {
      objects: '200+',
      developers: '15',
      avgRoi: '9.5%'
    },
    cities: [
      { 
        id: 'bali', 
        name: 'Бали',
        propertyCount: 145,
        priceFrom: '$180,000',
        types: ['Виллы', 'Апартаменты']
      },
      { 
        id: 'canggu', 
        name: 'Чангу',
        propertyCount: 95,
        priceFrom: '$220,000',
        types: ['Виллы', 'Резиденции']
      }
    ]
  },
  turkey: {
    name: 'Турция',
    image: 'https://images.unsplash.com/photo-1524231757912-21f4fe3a7200?ixlib=rb-4.0.3',
    description: 'Недвижимость в Турции: от курортных апартаментов до исторических особняков в Стамбуле',
    stats: {
      objects: '450+',
      developers: '30',
      avgRoi: '7.0%'
    },
    cities: [
      { 
        id: 'istanbul', 
        name: 'Стамбул',
        propertyCount: 280,
        priceFrom: '$120,000',
        types: ['Апартаменты', 'Пентхаусы']
      },
      { 
        id: 'antalya', 
        name: 'Анталья',
        propertyCount: 195,
        priceFrom: '$85,000',
        types: ['Апартаменты', 'Виллы']
      },
      { 
        id: 'alanya', 
        name: 'Аланья',
        propertyCount: 165,
        priceFrom: '$70,000',
        types: ['Апартаменты', 'Пентхаусы']
      }
    ]
  }
};

function Catalog() {
  return (
    <section className="catalog">
      <SEO 
        title="Каталог недвижимости"
        description="Выберите город для поиска недвижимости. Большой выбор объектов в популярных городах мира."
      />
      <div className="catalog-container">
        <div className="catalog-header">
          <h1 className="catalog-title">Каталог недвижимости</h1>
          <p className="catalog-description">
            Выберите город для поиска недвижимости. У нас представлены объекты в самых 
            популярных городах мира с высоким потенциалом для инвестиций.
          </p>
        </div>
        <div className="catalog-grid">
          {Object.entries(catalogData).map(([countryId, country]) => (
            <div key={countryId} className="country-card">
              <div 
                className="country-image" 
                style={{ backgroundImage: `url(${country.image})` }}
              />
              <div className="country-content">
                <div className="country-content-main">
                  <h2 className="country-name">{country.name}</h2>
                  <p className="country-content-description">{country.description}</p>
                </div>

                <div className="country-content-stats">
                  <div className="stats">
                    <div className="stats-item">
                      <div className="stats-value">{country.stats.objects}</div>
                      <div className="stats-label">Объектов</div>
                    </div>
                    <div className="stats-item">
                      <div className="stats-value">{country.stats.developers}</div>
                      <div className="stats-label">Застройщиков</div>
                    </div>
                    <div className="stats-item">
                      <div className="stats-value">{country.stats.avgRoi}</div>
                      <div className="stats-label">Доходность</div>
                    </div>
                  </div>
                </div>

                <div className="country-content-cities">
                  <h3 className="country-content-cities-title">Города</h3>
                  <div className="cities-list">
                    {country.cities.map(city => (
                      <div 
                        key={city.id} 
                        className="cities-card"
                        onClick={() => window.location.href = `/search/${city.id}/all`}
                      >
                        <div className="cities-header">
                          <h3>{city.name}</h3>
                          <span>от {city.priceFrom}</span>
                        </div>
                        <div className="cities-info">
                          <span>{city.propertyCount} объектов</span>
                        </div>
                        <div className="property-types">
                          {city.types.map((type, index) => (
                            <span key={index} className="property-type">{type}</span>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Catalog; 