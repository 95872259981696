import styled from 'styled-components';
import { Link } from 'react-router-dom';

const LogoWrapper = styled(Link)`
  display: flex;
  align-items: center;
  gap: 10px;
  text-decoration: none;
`;

const LogoIcon = styled.svg`
  width: 32px;
  height: 32px;
`;

const LogoText = styled.span`
  font-size: 24px;
  font-weight: 700;
  color: var(--text-color);
`;

function Logo() {
  return (
    <LogoWrapper to="/">
      <LogoIcon viewBox="0 0 48 48">
        <path 
          fillRule="evenodd" 
          clipRule="evenodd" 
          d="M24 23.9903C23.9948 10.7414 13.2501 0 0 0V24C0 37.2533 10.7467 48 24 48V24.0097C24.0052 37.2586 34.7499 48 48 48V24C48 10.7467 37.2533 0 24 0L24 23.9903Z" 
          fill="#703BF7"
        />
      </LogoIcon>
      <LogoText>OverSeas</LogoText>
    </LogoWrapper>
  );
}

export default Logo; 