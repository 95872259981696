import { useState, useEffect } from 'react';
import { Link, NavLink as RouterNavLink, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useAuth } from '../../contexts/AuthContext';
import Logo from '../Logo';

const HeaderWrapper = styled.header`
  background-color: var(--dark-bg);
  padding: 20px 0;
  position: relative;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  
  @media (max-width: 768px) {
    padding: 16px 0;
    position: sticky;
    top: 0;
    z-index: 1000;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
`;

const Nav = styled.nav`
  display: flex;
  gap: 32px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const NavLink = styled(RouterNavLink)`
  color: var(--text-color);
  text-decoration: none;
  font-weight: 500;
  padding: 14px 24px;
  border-radius: 8px;
  transition: all 0.2s ease;
  
  &:hover {
    color: var(--primary-color);
  }

  &.active {
    background: #141414;
    border: 1px solid #262626;
  }

  @media (max-width: 768px) {
    width: 100%;
    text-align: center;
    font-size: 18px;
    padding: 16px;
    margin-bottom: 8px;
    background: #141414;
    border: 1px solid #262626;
    
    &:active {
      transform: scale(0.98);
    }

    &.active {
      background: var(--primary-color);
      border-color: var(--primary-color);
      color: white;
    }
  }
`;

const AccountLink = styled(Link)`
  color: var(--text-color);
  text-decoration: none;
  font-weight: 500;
  padding: 14px 24px;
  border-radius: 8px;
  background: #141414;
  border: 1px solid #262626;
  
  &:hover {
    color: var(--primary-color);
  }

  @media (max-width: 768px) {
    display: none;

    &.mobile {
      display: block;
      width: 100%;
      text-align: center;
      margin-top: 16px;
      font-size: 18px;
      padding: 16px;
      background: transparent;
      border: 2px solid var(--primary-color);
      color: var(--primary-color);

      &:active {
        transform: scale(0.98);
      }
    }
  }
`;

const MobileMenuButton = styled.button`
  display: none;
  background: #141414;
  border: 1px solid #262626;
  color: var(--text-color);
  font-size: 20px;
  cursor: pointer;
  padding: 8px 12px;
  border-radius: 8px;
  transition: all 0.2s ease;
  
  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
  }

  &:hover {
    background: #1a1a1a;
    border-color: var(--primary-color);
    color: var(--primary-color);
  }

  &:active {
    transform: scale(0.95);
  }
`;

const MobileNav = styled.div`
  display: none;
  
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    background: rgba(20, 20, 20, 0.98);
    backdrop-filter: blur(10px);
    padding: 80px 20px 20px;
    transform: translateY(${props => props.$isOpen ? '0' : '-100%'});
    transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    z-index: 90;
  }
`;

function Header() {
  const { user } = useAuth();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const location = useLocation();

  // Закрываем меню при изменении маршрута
  useEffect(() => {
    setIsMobileMenuOpen(false);
    document.body.style.overflow = '';
  }, [location]);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
    document.body.style.overflow = !isMobileMenuOpen ? 'hidden' : '';
  };

  return (
    <HeaderWrapper>
      <HeaderContainer>
        <Logo />
        
        {/* Desktop Navigation */}
        <Nav>
          <NavLink to="/" end>Главная</NavLink>
          <NavLink to="/catalog">Каталог</NavLink>
          <NavLink to="/search">Недвижимость</NavLink>
        </Nav>
        
        <AccountLink to={user ? "/account" : "/login"}>Аккаунт</AccountLink>

        {/* Mobile Menu Button */}
        <MobileMenuButton 
          onClick={toggleMobileMenu}
          aria-label={isMobileMenuOpen ? 'Закрыть меню' : 'Открыть меню'}
        >
          <i className={`fas fa-${isMobileMenuOpen ? 'times' : 'bars'}`}></i>
        </MobileMenuButton>

        {/* Mobile Navigation */}
        <MobileNav $isOpen={isMobileMenuOpen}>
          <NavLink to="/" end onClick={toggleMobileMenu}>
            Главная
          </NavLink>
          <NavLink to="/catalog" onClick={toggleMobileMenu}>
            Каталог
          </NavLink>
          <NavLink to="/search" onClick={toggleMobileMenu}>
            Недвижимость
          </NavLink>
          <AccountLink to={user ? "/account" : "/login"} className="mobile" onClick={toggleMobileMenu}>
            Аккаунт
          </AccountLink>
        </MobileNav>
      </HeaderContainer>
    </HeaderWrapper>
  );
}

export default Header; 