import { Link } from 'react-router-dom';
import './styles.scss';

const cities = [
  {
    id: 1,
    name: 'Дубай',
    image: 'https://images.unsplash.com/photo-1512453979798-5ea266f8880c?ixlib=rb-4.0.3',
    value: 'dubai',
    type: 'all',
    description: 'Инвестиции от $80,000',
    stats: {
      roi: '8%',
      period: '5 лет'
    }
  },
  {
    id: 2,
    name: 'Москва',
    image: 'https://images.unsplash.com/photo-1513326738677-b964603b136d?ixlib=rb-4.0.3',
    value: 'moscow',
    type: 'new',
    description: 'Новостройки от 12млн ₽',
    stats: {
      roi: '6%',
      period: '3 года'
    }
  },
  {
    id: 3,
    name: 'Калининград',
    image: 'https://images.unsplash.com/photo-1697509583160-c9f8e8c95e9b?ixlib=rb-4.0.3',
    value: 'kaliningrad',
    type: 'secondary',
    description: 'Вторичка от 6млн ₽',
    stats: {
      roi: '5%',
      period: '2 года'
    }
  },
  {
    id: 4,
    name: 'Бали',
    image: 'https://images.unsplash.com/photo-1537996194471-e657df975ab4?ixlib=rb-4.0.3',
    value: 'bali',
    type: 'villas',
    description: 'Виллы от $250,000',
    stats: {
      roi: '10%',
      period: '7 лет'
    }
  }
];

function ProspectiveCities() {
  return (
    <section className="prospective-cities">
      <div className="container">
        <h2 className="title">Перспективные города для инвестиций</h2>
        <div className="cities-grid">
          {cities.map(city => (
            <Link 
              key={city.id} 
              to={`/search/${city.value}/${city.type}`}
              className="city-card"
            >
              <div 
                className="city-image" 
                style={{ backgroundImage: `url(${city.image})` }}
              />
              <div className="city-content">
                <h3 className="city-name">{city.name}</h3>
                <p className="city-description">{city.description}</p>
                <div className="city-stats">
                  <div className="stat">
                    <span className="stat-value">{city.stats.roi}</span>
                    <span className="stat-label">Доходность</span>
                  </div>
                  <div className="stat">
                    <span className="stat-value">{city.stats.period}</span>
                    <span className="stat-label">Срок</span>
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
}

export default ProspectiveCities; 