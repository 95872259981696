import { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import SEO from '../../components/SEO';
import PropertyFilters from './components/PropertyFilters';
import PropertyCard from './components/PropertyCard';
import { getCityName, getPropertyTypeName } from '../../config';
import './styles.scss';

function Search() {
  const { city = 'all', type = 'all' } = useParams();
  const [viewMode, setViewMode] = useState('grid'); // 'grid' или 'list'
  const [filters, setFilters] = useState({
    priceMin: '',
    priceMax: '',
    areaMin: '',
    areaMax: '',
    rooms: []
  });

  // Получаем читаемые названия из параметров URL
  const cityName = getCityName(city, true); // true для склонения
  const typeName = getPropertyTypeName(type);

  // Здесь будет запрос к API для получения списка объектов
  const properties = [
    {
      id: 1,
      title: '3-комнатная квартира',
      price: 25000000,
      location: {
        city: 'moscow',
        type: 'apartment',
        address: 'Климентовский пер., д. 2'
      },
      area: 70,
      rooms: 3,
      floor: 4,
      totalFloors: 12,
      images: ['https://images.unsplash.com/photo-1512453979798-5ea266f8880c'],
      isExclusive: true,
      complex: 'ЖК Premium',
      agent: {
        name: 'Мир Квартир 39',
        rating: 4.6
      }
    },
    {
      id: 2,
      title: 'Студия с видом на море',
      price: 12500000,
      location: {
        city: 'sochi',
        type: 'apartment',
        address: 'ул. Приморская, д. 15'
      },
      area: 28,
      rooms: 1,
      floor: 8,
      totalFloors: 15,
      images: ['https://images.unsplash.com/photo-1522708323590-d24dbb6b0267'],
      isExclusive: false,
      complex: 'ЖК Морской',
      agent: {
        name: 'Елена Морозова',
        rating: 4.8
      }
    },
    {
      id: 3,
      title: '2-комнатная квартира в центре',
      price: 18900000,
      location: {
        city: 'kaliningrad',
        type: 'apartment',
        address: 'Ленинский проспект, д. 8'
      },
      area: 54,
      rooms: 2,
      floor: 3,
      totalFloors: 6,
      images: ['https://images.unsplash.com/photo-1502672260266-1c1ef2d93688'],
      isExclusive: false,
      complex: 'ЖК Центральный',
      agent: {
        name: 'Александр Иванов',
        rating: 4.7
      }
    },
    {
      id: 4,
      title: 'Пентхаус с террасой',
      price: 45000000,
      location: {
        city: 'spb',
        type: 'apartment',
        address: 'Невский пр., д. 100'
      },
      area: 150,
      rooms: 4,
      floor: 10,
      totalFloors: 10,
      images: ['https://images.unsplash.com/photo-1600607687939-ce8a6c25118c'],
      isExclusive: true,
      complex: 'ЖК Империал',
      agent: {
        name: 'Premium Estate',
        rating: 4.9
      }
    },
    {
      id: 5,
      title: 'Апартаменты бизнес-класса',
      price: 32000000,
      location: {
        city: 'moscow',
        type: 'apartment',
        address: 'Пресненская наб., д. 10'
      },
      area: 85,
      rooms: 2,
      floor: 25,
      totalFloors: 45,
      images: ['https://images.unsplash.com/photo-1600585154340-be6161a56a0c'],
      isExclusive: false,
      complex: 'Москва-Сити',
      agent: {
        name: 'City Real Estate',
        rating: 4.5
      }
    }
  ];

  const handleFiltersChange = (newFilters) => {
    setFilters(newFilters);
  };

  return (
    <div className="search-page">
      <SEO 
        title={`${typeName} в ${cityName}`}
        description={`${typeName} в ${cityName} - актуальные предложения по продаже недвижимости`}
      />
      
      <div className="container">
        <div className="search-header">
          <div className="breadcrumbs">
            <Link to="/">Главная</Link>
            <span>/</span>
            <Link to="/search/all/all">Поиск</Link>
            {city !== 'all' && (
              <>
                <span>/</span>
                <Link to={`/search/${city}/all`}>{cityName}</Link>
              </>
            )}
            {type !== 'all' && (
              <>
                <span>/</span>
                <span>{typeName}</span>
              </>
            )}
          </div>
          <h1>{typeName} в {cityName}</h1>
        </div>

        <PropertyFilters 
          filters={filters}
          onChange={handleFiltersChange}
          currentCity={city}
          currentType={type}
        />

        <div className="properties-header">
          <div className="results-count">
            Найдено {properties.length} объектов
          </div>
          <div className="view-options">
            <button 
              className={viewMode === 'grid' ? 'active' : ''}
              onClick={() => setViewMode('grid')}
              title="Плитка"
            >
              <i className="fas fa-th-large"></i>
            </button>
            <button 
              className={viewMode === 'list' ? 'active' : ''}
              onClick={() => setViewMode('list')}
              title="Список"
            >
              <i className="fas fa-list"></i>
            </button>
          </div>
        </div>

        <div className={`properties-${viewMode}`}>
          {properties.map(property => (
            <PropertyCard 
              key={property.id}
              property={property}
              viewMode={viewMode}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Search; 