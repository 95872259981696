import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CITIES, PROPERTY_TYPES, POPULAR_DESTINATIONS, STATISTICS } from '../../../../config';
import './styles.scss';

const slides = [
  {
    id: 1,
    image: 'https://images.unsplash.com/photo-1600596542815-ffad4c1539a9?ixlib=rb-4.0.3',
    title: 'Luxury Villa in Bali'
  },
  {
    id: 2,
    image: 'https://images.unsplash.com/photo-1613490493576-7fde63acd811?ixlib=rb-4.0.3',
    title: 'Modern House in Singapore'
  },
  {
    id: 3,
    image: 'https://images.unsplash.com/photo-1512917774080-9991f1c4c750?ixlib=rb-4.0.3',
    title: 'Beachfront Property in Miami'
  }
];

function HeroSearch() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [selectedCity, setSelectedCity] = useState('all');
  const [selectedType, setSelectedType] = useState('all');
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % slides.length);
    }, 5000);
    return () => clearInterval(timer);
  }, []);

  const handleSearch = (e) => {
    e.preventDefault();
    navigate(`/search/${selectedCity}/${selectedType}`);
  };

  return (
    <section className="hero">
      <div className="hero-slider">
        {slides.map((slide, index) => (
          <div
            key={slide.id}
            className={`slide ${index === currentSlide ? 'active' : ''}`}
            style={{ backgroundImage: `url(${slide.image})` }}
          >
            <div className="slide-overlay"></div>
          </div>
        ))}
      </div>

      <div className="hero-container">
        <div className="hero-content">
          <h1 className="hero-title">
            <span className="hero-title-main">Недвижимость</span>
            <span className="hero-title-sub">по всему миру</span>
          </h1>
          
          <p className="hero-description">
            Инвестируйте в недвижимость в самых перспективных городах мира.
            От роскошных апартаментов до коммерческих помещений.
          </p>

          <div className="hero-search">
            <form className="search-box" onSubmit={handleSearch}>
              <select 
                value={selectedCity}
                onChange={(e) => setSelectedCity(e.target.value)}
                className="search-select"
              >
                {Object.values(CITIES).map(city => (
                  <option key={city.id} value={city.id}>
                    {city.name}
                  </option>
                ))}
              </select>

              <select 
                value={selectedType}
                onChange={(e) => setSelectedType(e.target.value)}
                className="search-select"
              >
                {Object.values(PROPERTY_TYPES).map(type => (
                  <option key={type.id} value={type.id}>
                    {type.name}
                  </option>
                ))}
              </select>

              <button type="submit" className="search-button">
                <i className="fas fa-search"></i>
                Найти
              </button>
            </form>

            <div className="popular-searches">
              <span>Популярные направления:</span>
              <div className="search-tags">
                {POPULAR_DESTINATIONS.map((destination) => (
                  <a 
                    key={`${destination.cityId}-${destination.typeId}`}
                    href={`/search/${destination.cityId}/${destination.typeId}`}
                    className="tag"
                  >
                    {destination.title}
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroSearch; 