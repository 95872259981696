import { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import SEO from '../../components/SEO';
import './styles.scss';

function ObjectPage() {
  const { id } = useParams();
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const thumbnailsRef = useRef(null);

  // Здесь будет запрос к API для получения данных объекта
  const property = {
    id: 1,
    title: 'Cosy apartment for rent',
    address: '164 A route du Vallon de la Lauvette, 06000 Nice',
    price: 25000000,
    agent: {
      name: 'Мир Квартир 39',
      rating: 4.6,
      reviews: 12
    },
    features: {
      bedrooms: 2,
      bathrooms: 1,
      parking: '1 парковочное место',
      area: '1,250 м²'
    },
    description: `Located on the plateau central and close to all amenities, the location of this apartment on the first floor of a downtown building will be ideal for your "chill and work". A large living room open to the kitchen and the work area with sofabed, a flat screen TV, coffee table. A dining table for your taste your best dishes and four chairs. A fully equipped kitchen with dishwasher, fridge, freezer, oven, microwave, coffee maker, kettle, toaster and all the necessary utensils for cooking. A bedroom with a double bed (140x200), bedside tables, reading lamps and a large closet. A bathroom with a walk-in shower, sink and toilet. A washing machine is available. The apartment is equipped with high-speed wifi, air conditioning and heating.`,
    images: [
      'https://images.unsplash.com/photo-1497366216548-37526070297c',
      'https://images.unsplash.com/photo-1497366216548-37526070297c',
      'https://images.unsplash.com/photo-1497366216548-37526070297c',
      'https://images.unsplash.com/photo-1497366216548-37526070297c',
      'https://images.unsplash.com/photo-1497366216548-37526070297c',
      'https://images.unsplash.com/photo-1497366216548-37526070297c',
      'https://images.unsplash.com/photo-1497366216548-37526070297c',
      'https://images.unsplash.com/photo-1497366216548-37526070297c',
      'https://images.unsplash.com/photo-1497366216548-37526070297c',
      'https://images.unsplash.com/photo-1497366216548-37526070297c',
      'https://images.unsplash.com/photo-1502672260266-1c1ef2d93688',
      'https://images.unsplash.com/photo-1512453979798-5ea266f8880c',
      'https://images.unsplash.com/photo-1600607687939-ce8a6c25118c',
      'https://images.unsplash.com/photo-1600585154340-be6161a56a0c'
    ]
  };

  const handlePrevImage = () => {
    setActiveImageIndex((prev) => 
      prev === 0 ? property.images.length - 1 : prev - 1
    );
  };

  const handleNextImage = () => {
    setActiveImageIndex((prev) => 
      prev === property.images.length - 1 ? 0 : prev + 1
    );
  };

  const handleThumbnailClick = (index) => {
    setActiveImageIndex(index);
  };

  // Добавим эффект для прокрутки к активной миниатюре
  useEffect(() => {
    if (thumbnailsRef.current) {
      const container = thumbnailsRef.current;
      const activeThumb = container.children[activeImageIndex];
      
      if (activeThumb) {
        const containerWidth = container.offsetWidth;
        const thumbLeft = activeThumb.offsetLeft;
        const thumbWidth = activeThumb.offsetWidth;
        
        // Вычисляем позицию для центрирования активной миниатюры
        const scrollPosition = thumbLeft - (containerWidth / 2) + (thumbWidth / 2);
        
        container.scrollTo({
          left: scrollPosition,
          behavior: 'smooth'
        });
      }
    }
  }, [activeImageIndex]);

  return (
    <div className="object-page">
      <SEO 
        title={property.title}
        description={property.description}
      />

      <div className={`gallery-section ${isFullscreen ? 'fullscreen' : ''}`}>
        <div className="gallery-container">
          <div className="main-image">
            <img src={property.images[activeImageIndex]} alt={property.title} />
            
            <button className="gallery-nav prev" onClick={handlePrevImage}>
              <i className="fas fa-chevron-left"></i>
            </button>
            
            <button className="gallery-nav next" onClick={handleNextImage}>
              <i className="fas fa-chevron-right"></i>
            </button>

            <button 
              className="fullscreen-toggle"
              onClick={() => setIsFullscreen(!isFullscreen)}
            >
              <i className={`fas fa-${isFullscreen ? 'compress' : 'expand'}`}></i>
            </button>

            <div className="image-counter">
              {activeImageIndex + 1} / {property.images.length}
            </div>
          </div>

          <div className="thumbnails-container" ref={thumbnailsRef}>
            {property.images.map((image, index) => (
              <div 
                key={index}
                className={`thumbnail ${index === activeImageIndex ? 'active' : ''}`}
                onClick={() => handleThumbnailClick(index)}
              >
                <img src={image} alt={`${property.title} - фото ${index + 1}`} />
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="content-section">
        <div className="content-container">
          <div className="main-content">
            <div className="object-header">
              <h1>{property.title}</h1>
              <div className="object-address">
                <i className="fas fa-map-marker-alt"></i> {property.address}
              </div>
            </div>

            <div className="agent-info">
              <div className="agent-avatar">
                <div className="avatar-placeholder"></div>
              </div>
              <div className="agent-details">
                <div className="agent-name">{property.agent.name}</div>
                <div className="agent-stats">
                  <div className="agent-rating">
                    <i className="fas fa-star"></i>
                    <span>{property.agent.rating}</span>
                  </div>
                  <span className="reviews-count">{property.agent.reviews} отзывов</span>
                </div>
              </div>
            </div>

            <div className="features-grid">
              <div className="feature">
                <i className="fas fa-bed"></i>
                <div className="feature-label">Спальни</div>
                <div className="feature-value">{property.features.bedrooms} спальни</div>
              </div>
              <div className="feature">
                <i className="fas fa-bath"></i>
                <div className="feature-label">Санузлы</div>
                <div className="feature-value">{property.features.bathrooms} санузел</div>
              </div>
              <div className="feature">
                <i className="fas fa-parking"></i>
                <div className="feature-label">Парковка</div>
                <div className="feature-value">{property.features.parking}</div>
              </div>
              <div className="feature">
                <i className="fas fa-vector-square"></i>
                <div className="feature-label">Площадь</div>
                <div className="feature-value">{property.features.area}</div>
              </div>
            </div>

            <div className="description">
              <h2>Description:</h2>
              <p>{property.description}</p>
            </div>
          </div>

          <div className="sidebar">
            <div className="price-card">
              <div className="price">
                {property.price.toLocaleString()} ���
              </div>
              <button className="contact-btn">
                Связаться с риелтором
              </button>
              <button className="favorite-btn">
                <i className="far fa-heart"></i> В избранное
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ObjectPage; 