import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './styles.scss';

const partners = [
  {
    id: 1,
    name: 'Мир Квартир 39',
    location: 'Калининград, Россия',
    logo: 'https://via.placeholder.com/200x100?text=MK39',
    description: 'Более 15 лет опыта в сфере недвижимости. Наша компания является лидером рынка в Калининградской области.',
    stats: {
      deals: '1500+',
      experience: '15 лет',
      rating: '4.9'
    },
    contacts: {
      phone: '+7 (401) 234-56-78',
      email: 'info@mk39.ru',
      address: 'ул. Черняховского, 6, Калининград'
    }
  },
  {
    id: 2,
    name: 'Dubai Property Group',
    location: 'Дубай, ОАЭ',
    logo: 'https://via.placeholder.com/200x100?text=DPG',
    description: 'Специализируемся на элитной недвижимости и инвестиционных проектах в ОАЭ. Индивидуальный подход к каждому клиенту.',
    stats: {
      deals: '2000+',
      experience: '10 лет',
      rating: '4.8'
    },
    contacts: {
      phone: '+971 4 123 4567',
      email: 'info@dpg.ae',
      address: 'Dubai Marina, Dubai'
    }
  },
  {
    id: 3,
    name: 'Bali Luxury Villas',
    location: 'Бали, Индонезия',
    logo: 'https://via.placeholder.com/200x100?text=BLV',
    description: 'Эксперты по виллам и инвестиционным проектам на Бали. Полное сопровождение сделок и управление недвижимостью.',
    stats: {
      deals: '800+',
      experience: '8 лет',
      rating: '4.7'
    },
    contacts: {
      phone: '+62 361 123 456',
      email: 'info@blv.id',
      address: 'Jl. Sunset Road, Kuta, Bali'
    }
  },
  {
    id: 4,
    name: 'Moscow City Realty',
    location: 'Москва, Россия',
    logo: 'https://via.placeholder.com/200x100?text=MCR',
    description: 'Ведущий брокер по продаже и аренде недвижимости в Москве. Работаем с новостройками и вторичным жильем.',
    stats: {
      deals: '5000+',
      experience: '12 лет',
      rating: '4.9'
    },
    contacts: {
      phone: '+7 (495) 123-45-67',
      email: 'info@mcr.ru',
      address: 'Пресненская наб., 12, Москва'
    }
  }
];

function Partners() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  return (
    <section className="partners">
      <div className="container">
        <div className="header">
          <h2 className="title">Наши партнёры</h2>
          <button>Стать нашим партнёром</button>
        </div>
        <Slider {...settings} className="partners-slider">
          {partners.map((partner) => (
            <div key={partner.id} className="slider-item">
              <div className="partner-card">
                <div className="partner-header">
                  <img className="partner-logo" src={partner.logo} alt={partner.name} />
                  <div className="partner-info">
                    <h3 className="partner-name">{partner.name}</h3>
                    <p className="partner-location">{partner.location}</p>
                  </div>
                </div>
                <p className="partner-description">{partner.description}</p>
                <div className="partner-stats">
                  <div className="stat">
                    <span className="stat-value">{partner.stats.deals}</span>
                    <span className="stat-label">Сделок</span>
                  </div>
                  <div className="stat">
                    <span className="stat-value">{partner.stats.experience}</span>
                    <span className="stat-label">Опыт</span>
                  </div>
                  <div className="stat">
                    <span className="stat-value">{partner.stats.rating}</span>
                    <span className="stat-label">Рейтинг</span>
                  </div>
                </div>
                <div className="partner-contacts">
                  <div className="contact">
                    <span className="contact-label">Телефон:</span>
                    <a href={`tel:${partner.contacts.phone}`} className="contact-value">
                      {partner.contacts.phone}
                    </a>
                  </div>
                  <div className="contact">
                    <span className="contact-label">Email:</span>
                    <a href={`mailto:${partner.contacts.email}`} className="contact-value">
                      {partner.contacts.email}
                    </a>
                  </div>
                  <div className="contact">
                    <span className="contact-label">Адрес:</span>
                    <span className="contact-value">{partner.contacts.address}</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
}

export default Partners; 