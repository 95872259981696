import { Helmet } from 'react-helmet-async';

function SEO({ title, description, keywords, image, url }) {
  const siteTitle = 'OverSeas';
  const fullTitle = title ? `${title} | ${siteTitle}` : siteTitle;
  
  const schemaOrgWebsite = {
    '@context': 'https://schema.org',
    '@type': 'RealEstateAgent',
    'name': 'OverSeas Real Estate',
    'description': description,
    'url': 'https://overseas-realty.com',
    'logo': 'https://overseas-realty.com/logo.png',
    'sameAs': [
      'https://facebook.com/overseas',
      'https://instagram.com/overseas',
      'https://linkedin.com/company/overseas'
    ],
    'address': {
      '@type': 'PostalAddress',
      'addressCountry': 'Russia',
      'addressLocality': 'Moscow'
    },
    'contactPoint': {
      '@type': 'ContactPoint',
      'telephone': '+7-800-123-45-67',
      'contactType': 'customer service'
    }
  };

  return (
    <Helmet>
      <title>{fullTitle}</title>
      {description && <meta name="description" content={description} />}
      {keywords && <meta name="keywords" content={keywords} />}
      
      <meta property="og:title" content={fullTitle} />
      {description && <meta property="og:description" content={description} />}
      {image && <meta property="og:image" content={image} />}
      {url && <meta property="og:url" content={url} />}
      
      <link rel="canonical" href={url || window.location.href} />

      {/* Twitter Card data */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={fullTitle} />
      {description && <meta name="twitter:description" content={description} />}
      {image && <meta name="twitter:image" content={image} />}

      {/* Schema.org markup */}
      <script type="application/ld+json">
        {JSON.stringify(schemaOrgWebsite)}
      </script>
    </Helmet>
  );
}

export default SEO; 