import './styles.scss';
import Logo from '../Logo';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-column">
          <Logo />
          <div className="contact-info">
            Международное агентство недвижимости с полным спектром услуг по подбору и сопровождению сделок
          </div>
          <div className="social-links">
            <a href="#" className="social-link" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-telegram"></i>
            </a>
            <a href="#" className="social-link" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-whatsapp"></i>
            </a>
            <a href="#" className="social-link" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-instagram"></i>
            </a>
          </div>
        </div>
        <div className="footer-column">
          <h3 className="title">О компании</h3>
          <a className="footer-link" href="/about">О нас</a>
          <a className="footer-link" href="/team">Наша команда</a>
          <a className="footer-link" href="/careers">Вакансии</a>
          <a className="footer-link" href="/blog">Блог</a>
        </div>
        <div className="footer-column">
          <h3 className="title">Услуги</h3>
          <a className="footer-link" href="/properties">Недвижимость</a>
          <a className="footer-link" href="/investment">Инвестиции</a>
          <a className="footer-link" href="/consulting">Консультации</a>
          <a className="footer-link" href="/management">Управление</a>
        </div>
        <div className="footer-column">
          <h3 className="title">Контакты</h3>
          <div className="contact-info">
            Телефон: +7 (800) 123-45-67<br />
            Email: info@overseas.com<br />
            Адрес: г. Москва, ул. Тверская, 1
          </div>
          <a className="footer-link" href="/contact">Связаться с нами</a>
          <a className="footer-link" href="/offices">Наши офисы</a>
        </div>
      </div>
      <div className="copyright">
        © 2024 OverSeas. Все права защищены.
      </div>
    </footer>
  );
}

export default Footer; 