import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Catalog from './pages/Catalog';
import Search from './pages/Search';
import Account from './pages/Account';
import ObjectPage from './pages/Object';
import Login from './pages/Login';
import Register from './pages/Register';
import ProtectedRoute from './components/ProtectedRoute';
import { AuthProvider } from './contexts/AuthContext';
import './App.css';

function App() {
	return (
		<HelmetProvider>
			<AuthProvider>
				<Router>
					<div className="App">
						<Header />
						<Routes>
							<Route path="/" element={<Home />} />
							<Route path="/catalog" element={<Catalog />} />
							<Route path="/search" element={<Search />} />
							<Route path="/search/:city/:type" element={<Search />} />
							<Route path="/login" element={<Login />} />
							<Route path="/register" element={<Register />} />
							<Route 
								path="/account" 
								element={
									<ProtectedRoute>
										<Account />
									</ProtectedRoute>
								} 
							/>
							<Route path="/object/:id" element={<ObjectPage />} />
						</Routes>
						<Footer />
					</div>
				</Router>
			</AuthProvider>
		</HelmetProvider>
	);
}

export default App;
