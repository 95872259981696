import { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Проверяем наличие токена в localStorage
    const token = localStorage.getItem('token');
    if (token) {
      // TODO: Здесь будет запрос к API для проверки токена
      setUser({ id: 1, email: 'test@example.com' });
    }
    setLoading(false);
  }, []);

  const login = async (email, password) => {
    // TODO: Здесь будет запрос к API для логина
    const fakeUser = { id: 1, email };
    localStorage.setItem('token', 'fake-token');
    setUser(fakeUser);
  };

  const register = async (email, password) => {
    // TODO: Здесь будет запрос к API для регистрации
    const fakeUser = { id: 1, email };
    localStorage.setItem('token', 'fake-token');
    setUser(fakeUser);
  };

  const logout = () => {
    localStorage.removeItem('token');
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ user, login, register, logout, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext); 