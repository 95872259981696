import { useState } from 'react';
import './styles.scss';

function UserProfile({ name, rating, reviews, earnings, views }) {
  const [showStats, setShowStats] = useState(true);

  return (
    <div className="user-profile">
      <div className="profile-header">
        <div className="user-info">
          <div className="user-avatar">
            <img src="https://via.placeholder.com/80" alt="User avatar" />
            <div className="avatar-badge online" />
          </div>
          <div className="user-details">
            <div className="user-name-wrapper">
              <h1 className="user-name">{name}</h1>
              <span className="pro-badge">PRO</span>
            </div>
            <div className="user-meta">
              <div className="rating">
                <span className="rating-stars">★ {rating}</span>
                <span className="reviews-count">{reviews} отзывов</span>
              </div>
              <div className="account-status">
                <span className="status-badge">Активный продавец</span>
                <span className="member-since">На сайте с 2023 года</span>
              </div>
            </div>
          </div>
        </div>
        <div className="profile-actions">
          <button className="action-button primary">
            <i className="fas fa-plus"></i>
            Разместить объявление
          </button>
          <button className="action-button secondary">
            <i className="fas fa-cog"></i>
            Настройки
          </button>
        </div>
      </div>

      <div className="stats-section">
        <div className="stats-header">
          <h2>Статистика</h2>
          <button 
            className="toggle-stats"
            onClick={() => setShowStats(!showStats)}
          >
            <i className={`fas fa-chevron-${showStats ? 'up' : 'down'}`}></i>
          </button>
        </div>

        {showStats && (
          <div className="stats-grid">
            <div className="stat-card primary">
              <div className="stat-icon">
                <i className="fas fa-wallet"></i>
              </div>
              <div className="stat-content">
                <div className="stat-value">{earnings}</div>
                <div className="stat-label">Доход за все время</div>
              </div>
              <div className="stat-trend positive">
                <i className="fas fa-arrow-up"></i>
                12.5%
              </div>
            </div>

            <div className="stat-card">
              <div className="stat-icon">
                <i className="fas fa-eye"></i>
              </div>
              <div className="stat-content">
                <div className="stat-value">{views.today}</div>
                <div className="stat-label">Просмотров сегодня</div>
              </div>
            </div>

            <div className="stat-card">
              <div className="stat-icon">
                <i className="fas fa-chart-line"></i>
              </div>
              <div className="stat-content">
                <div className="stat-value">{views.period}</div>
                <div className="stat-label">За {views.periodDays} дней</div>
              </div>
            </div>

            <div className="stat-card">
              <div className="stat-icon">
                <i className="fas fa-shopping-cart"></i>
              </div>
              <div className="stat-content">
                <div className="stat-value">24</div>
                <div className="stat-label">Активных объявлений</div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default UserProfile; 