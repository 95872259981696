import './styles.scss';

function ListingsFilters({ searchQuery, onSearchChange, filters, onFiltersChange }) {
  const handleFilterChange = (key, value) => {
    onFiltersChange({ ...filters, [key]: value });
  };

  return (
    <div className="listings-filters">
      <div className="search-box">
        <input
          type="text"
          placeholder="Поиск по названию, описанию и адресу"
          value={searchQuery}
          onChange={(e) => onSearchChange(e.target.value)}
          className="search-input"
        />
        <label className="search-checkbox">
          <input type="checkbox" />
          <span>Искать только в названиях</span>
        </label>
      </div>

      <div className="filters-grid">
        <select 
          value={filters.promotionServices} 
          onChange={(e) => handleFilterChange('promotionServices', e.target.value)}
          className="filter-select"
        >
          <option value="">Услуги продвижения</option>
          <option value="promoted">Продвигаемые</option>
          <option value="regular">Обычные</option>
        </select>

        <select 
          value={filters.discountMailings} 
          onChange={(e) => handleFilterChange('discountMailings', e.target.value)}
          className="filter-select"
        >
          <option value="">Рассылка скидок</option>
          <option value="active">Активные</option>
          <option value="inactive">Неактивные</option>
        </select>

        <div className="price-range">
          <input
            type="number"
            placeholder="Цена от"
            value={filters.priceFrom}
            onChange={(e) => handleFilterChange('priceFrom', e.target.value)}
            className="price-input"
          />
          <span>до</span>
          <input
            type="number"
            placeholder="Цена до"
            value={filters.priceTo}
            onChange={(e) => handleFilterChange('priceTo', e.target.value)}
            className="price-input"
          />
          <span>₽</span>
        </div>

        <select 
          value={filters.category} 
          onChange={(e) => handleFilterChange('category', e.target.value)}
          className="filter-select"
        >
          <option value="">Категория</option>
          <option value="apartment">Квартиры</option>
          <option value="house">Дома</option>
          <option value="commercial">Коммерческая</option>
        </select>

        <select 
          value={filters.avitoDelivery} 
          onChange={(e) => handleFilterChange('avitoDelivery', e.target.value)}
          className="filter-select"
        >
          <option value="">Авито Доставка</option>
          <option value="yes">Включена</option>
          <option value="no">Выключена</option>
        </select>

        <div className="date-filter">
          <span>На Авито от</span>
          <input
            type="number"
            value={filters.dateRange}
            onChange={(e) => handleFilterChange('dateRange', e.target.value)}
            className="date-input"
          />
          <span>дней</span>
        </div>
      </div>

      <button className="reset-filters" onClick={() => onFiltersChange({})}>
        <i className="fas fa-times"></i> Сбросить фильтры
      </button>
    </div>
  );
}

export default ListingsFilters; 