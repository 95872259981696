import { Link } from 'react-router-dom';
import './styles.scss';

function PropertyCard({ property }) {
  const {
    id,
    title,
    price,
    area,
    images,
    agent = {
      name: 'Мир Квартир 39',
      rating: 4.6
    }
  } = property;

  return (
    <Link to={`/object/${id}`} className="property-card">
      <div className="property-image">
        <img src={images?.[0] || 'placeholder.jpg'} alt={title} />
        <div className="image-counter">
          <i className="fas fa-camera"></i>
          {images?.length || 0} фотографий
        </div>
      </div>
      
      <div className="property-content">
        <h3 className="property-title">{title}</h3>
        <div className="property-price">{price.toLocaleString()} ₽</div>
        <div className="property-area">{area} м²</div>
        
        <div className="property-agent">
          <div className="agent-avatar">
            <div className="avatar-placeholder"></div>
          </div>
          <div className="agent-info">
            <div className="agent-name">{agent.name}</div>
            <div className="agent-rating">
              {agent.rating} <i className="fas fa-star"></i>
            </div>
          </div>
        </div>

        <div className="buttons-group">
          <button className="favorite-btn" onClick={(e) => e.preventDefault()}>
            <i className="far fa-heart"></i>
          </button>
          <button className="contact-btn" onClick={(e) => e.preventDefault()}>
            Связаться
          </button>
        </div>
      </div>
    </Link>
  );
}

export default PropertyCard; 