import { useState, useRef, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CITIES, PROPERTY_TYPES, ROOM_TYPES } from '../../../../config';
import './styles.scss';

function PropertyFilters({ 
  filters, 
  onChange, 
  currentCity, 
  currentType
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isTypeOpen, setIsTypeOpen] = useState(false);
  const [isCityOpen, setIsCityOpen] = useState(false);
  const [isAllFiltersOpen, setIsAllFiltersOpen] = useState(false);
  const [extendedFilters, setExtendedFilters] = useState({
    minPrice: searchParams.get('min_price') || '',
    maxPrice: searchParams.get('max_price') || '',
    minSquares: searchParams.get('min_squares') || '',
    maxSquares: searchParams.get('max_squares') || '',
    rooms: searchParams.get('rooms')?.split(',').filter(Boolean) || []
  });

  const navigate = useNavigate();

  const cities = Object.values(CITIES);
  const types = Object.values(PROPERTY_TYPES);

  const selectedCity = cities.find(c => c.id === currentCity) || cities[0];
  const selectedType = types.find(t => t.id === currentType) || types[0];

  const typeDropdownRef = useRef(null);
  const cityDropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (typeDropdownRef.current && !typeDropdownRef.current.contains(event.target)) {
        setIsTypeOpen(false);
      }
      if (cityDropdownRef.current && !cityDropdownRef.current.contains(event.target)) {
        setIsCityOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleTypeSelect = (type) => {
    setIsTypeOpen(false);
    navigate(`/search/${currentCity}/${type.id}`);
  };

  const handleCitySelect = (city) => {
    setIsCityOpen(false);
    navigate(`/search/${city.id}/${currentType}`);
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setExtendedFilters(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleRoomToggle = (roomId) => {
    setExtendedFilters(prev => ({
      ...prev,
      rooms: prev.rooms.includes(roomId)
        ? prev.rooms.filter(id => id !== roomId)
        : [...prev.rooms, roomId]
    }));
  };

  const applyFilters = () => {
    const params = new URLSearchParams(searchParams);

    if (extendedFilters.minPrice) params.set('min_price', extendedFilters.minPrice);
    else params.delete('min_price');

    if (extendedFilters.maxPrice) params.set('max_price', extendedFilters.maxPrice);
    else params.delete('max_price');

    if (extendedFilters.minSquares) params.set('min_squares', extendedFilters.minSquares);
    else params.delete('min_squares');

    if (extendedFilters.maxSquares) params.set('max_squares', extendedFilters.maxSquares);
    else params.delete('max_squares');

    if (extendedFilters.rooms.length) params.set('rooms', extendedFilters.rooms.join(','));
    else params.delete('rooms');

    setSearchParams(params);
    setIsAllFiltersOpen(false);
  };

  return (
    <div className="property-filters">
      <div className="filters-container">
        <div className="filters-row">
          <div className="filter-dropdown" ref={typeDropdownRef}>
            <button 
              className={`dropdown-toggle ${isTypeOpen ? 'active' : ''}`}
              onClick={() => setIsTypeOpen(!isTypeOpen)}
            >
              {selectedType.name}
              <i className={`fas fa-chevron-down ${isTypeOpen ? 'open' : ''}`}></i>
            </button>
            
            {isTypeOpen && (
              <div className="dropdown-menu">
                {types.map(type => (
                  <button
                    key={type.id}
                    className={`dropdown-item ${selectedType.id === type.id ? 'active' : ''}`}
                    onClick={() => handleTypeSelect(type)}
                  >
                    {type.name}
                  </button>
                ))}
              </div>
            )}
          </div>

          <div className="filter-dropdown" ref={cityDropdownRef}>
            <button 
              className={`dropdown-toggle ${isCityOpen ? 'active' : ''}`}
              onClick={() => setIsCityOpen(!isCityOpen)}
            >
              {selectedCity.name}
              <i className={`fas fa-chevron-down ${isCityOpen ? 'open' : ''}`}></i>
            </button>

            {isCityOpen && (
              <div className="dropdown-menu">
                {cities.map(city => (
                  <button
                    key={city.id}
                    className={`dropdown-item ${selectedCity.id === city.id ? 'active' : ''}`}
                    onClick={() => handleCitySelect(city)}
                  >
                    {city.name}
                  </button>
                ))}
              </div>
            )}
          </div>

          <button 
            className={`all-filters-btn ${isAllFiltersOpen ? 'active' : ''}`}
            onClick={() => setIsAllFiltersOpen(!isAllFiltersOpen)}
          >
            <i className="fas fa-sliders-h"></i>
            Все фильтры
          </button>
        </div>

        {isAllFiltersOpen && (
          <div className="extended-filters">
            <div className="filter-group">
              <h3>Цена</h3>
              <div className="price-inputs">
                <input 
                  type="number" 
                  name="minPrice"
                  placeholder="от" 
                  value={extendedFilters.minPrice}
                  onChange={handleFilterChange}
                />
                <input 
                  type="number" 
                  name="maxPrice"
                  placeholder="до" 
                  value={extendedFilters.maxPrice}
                  onChange={handleFilterChange}
                />
              </div>
            </div>

            <div className="filter-group">
              <h3>Комнат</h3>
              <div className="room-options">
                {ROOM_TYPES.map(room => (
                  <button 
                    key={room.id}
                    className={`room-btn ${extendedFilters.rooms.includes(room.id) ? 'active' : ''}`}
                    onClick={() => handleRoomToggle(room.id)}
                  >
                    {room.name}
                  </button>
                ))}
              </div>
            </div>

            <div className="filter-group">
              <h3>Площадь, м²</h3>
              <div className="price-inputs">
                <input 
                  type="number" 
                  name="minSquares"
                  placeholder="от" 
                  value={extendedFilters.minSquares}
                  onChange={handleFilterChange}
                />
                <input 
                  type="number" 
                  name="maxSquares"
                  placeholder="до" 
                  value={extendedFilters.maxSquares}
                  onChange={handleFilterChange}
                />
              </div>
            </div>

            <button className="apply-filters-btn" onClick={applyFilters}>
              Применить фильтры
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default PropertyFilters;