import { useState } from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const countries = [
  { id: 'all', name: 'Все страны' },
  { id: 'uae', name: 'ОАЭ', cities: ['Dubai', 'Abu Dhabi'] },
  { id: 'russia', name: 'Россия', cities: ['Москва', 'Калининград'] },
  { id: 'indonesia', name: 'Индонезия', cities: ['Бали'] },
  { id: 'thailand', name: 'Таиланд', cities: ['Пхукет', 'Паттайя'] }
];

const properties = [
  {
    id: 1,
    name: 'Marina Heights',
    country: 'uae',
    location: 'Dubai Marina, Dubai',
    image: 'https://images.unsplash.com/photo-1512453979798-5ea266f8880c?ixlib=rb-4.0.3',
    price: {
      value: 500000,
      currency: '$'
    },
    type: 'Апартаменты',
    roi: {
      percent: '8.5',
      period: '5 лет'
    },
    area: {
      value: 85,
      unit: 'м²'
    },
    features: ['Вид на море', 'Рассрочка', 'Паркинг']
  },
  {
    id: 2,
    name: 'Palm View Residence',
    country: 'uae',
    location: 'Palm Jumeirah, Dubai',
    image: 'https://images.unsplash.com/photo-1582268611958-ebfd161ef9cf?ixlib=rb-4.0.3',
    price: {
      value: 850000,
      currency: '$'
    },
    type: 'Апартаменты',
    roi: {
      percent: '9.2',
      period: '5 лет'
    },
    area: {
      value: 120,
      unit: 'м²'
    },
    features: ['Первая линия', 'Частный пляж', 'Премиум отделка']
  },
  {
    id: 3,
    name: 'Москва Сити - Capital Towers',
    country: 'russia',
    location: 'Пресненская набережная, Москва',
    image: 'https://images.unsplash.com/photo-1513326738677-b964603b136d?ixlib=rb-4.0.3',
    price: {
      value: 45000000,
      currency: '₽'
    },
    type: 'Апартаменты',
    roi: {
      percent: '6.5',
      period: '3 года'
    },
    area: {
      value: 75,
      unit: 'м²'
    },
    features: ['Премиум класс', 'Вид на город', 'Консьерж']
  },
  {
    id: 4,
    name: 'Светлогорск Резиденция',
    country: 'russia',
    location: 'Светлогорск, Калининград',
    image: 'https://images.unsplash.com/photo-1545324418-cc1a3fa10c00?ixlib=rb-4.0.3',
    price: {
      value: 12000000,
      currency: '₽'
    },
    type: 'Апартаменты',
    roi: {
      percent: '7.0',
      period: '4 года'
    },
    area: {
      value: 65,
      unit: 'м²'
    },
    features: ['Вид на море', 'Закрытая территория', 'Подземный паркинг']
  },
  {
    id: 5,
    name: 'Canggu Beach Villas',
    country: 'indonesia',
    location: 'Canggu, Bali',
    image: 'https://images.unsplash.com/photo-1505321099522-6580c6016c46?ixlib=rb-4.0.3',
    price: {
      value: 350000,
      currency: '$'
    },
    type: 'Вилла',
    roi: {
      percent: '11.0',
      period: '7 лет'
    },
    area: {
      value: 180,
      unit: 'м²'
    },
    features: ['Бассейн', 'Тропический сад', 'Управление']
  },
  {
    id: 6,
    name: 'Uluwatu Cliff Villa',
    country: 'indonesia',
    location: 'Uluwatu, Bali',
    image: 'https://images.unsplash.com/photo-1505321099522-6580c6016c46?ixlib=rb-4.0.3',
    price: {
      value: 420000,
      currency: '$'
    },
    type: 'Вилла',
    roi: {
      percent: '10.5',
      period: '7 лет'
    },
    area: {
      value: 220,
      unit: 'м²'
    },
    features: ['Вид на океан', 'Инфинити бассейн', 'Обслуживание']
  },
  {
    id: 7,
    name: 'Phuket Sea View',
    country: 'thailand',
    location: 'Камала, Пхукет',
    image: 'https://images.unsplash.com/photo-1582268611958-ebfd161ef9cf?ixlib=rb-4.0.3',
    price: {
      value: 280000,
      currency: '$'
    },
    type: 'Апартаменты',
    roi: {
      percent: '9.8',
      period: '5 лет'
    },
    area: {
      value: 95,
      unit: 'м²'
    },
    features: ['Вид на море', 'Бассейн', 'Спа-центр']
  },
  {
    id: 8,
    name: 'Pattaya Beach Condo',
    country: 'thailand',
    location: 'Джомтьен, Паттайя',
    image: 'https://images.unsplash.com/photo-1512453979798-5ea266f8880c?ixlib=rb-4.0.3',
    price: {
      value: 180000,
      currency: '$'
    },
    type: 'Апартаменты',
    roi: {
      percent: '8.8',
      period: '5 лет'
    },
    area: {
      value: 68,
      unit: 'м²'
    },
    features: ['Первая линия', 'Фитнес-центр', 'Охрана']
  }
];

const Section = styled.section`
  padding: 80px 0;
  background: #111;

  @media (max-width: 768px) {
    padding: 60px 0;
  }
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
`;

const Header = styled.div`
  margin-bottom: 40px;
  text-align: center;

  @media (max-width: 768px) {
    margin-bottom: 30px;
  }
`;

const Title = styled.h2`
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 28px;
  }
`;

const CountrySelect = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 40px;
  flex-wrap: wrap;
  justify-content: center;

  @media (max-width: 768px) {
    gap: 10px;
    margin-bottom: 30px;
  }

  @media (max-width: 480px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
  }
`;

const CountryButton = styled.button`
  background: ${props => props.$isActive ? '#141414' : 'transparent'};
  border: 1px solid ${props => props.$isActive ? '#262626' : 'transparent'};
  color: ${props => props.$isActive ? 'white' : '#888'};
  padding: 14px 24px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  white-space: nowrap;

  @media (max-width: 768px) {
    padding: 12px 20px;
    font-size: 14px;
  }

  @media (max-width: 480px) {
    padding: 10px 16px;
    width: 100%;
    white-space: normal;
    text-align: center;
  }

  &:hover {
    color: white;
    background: #141414;
    border-color: #262626;
  }
`;

const SliderWrapper = styled.div`
  .slick-slide {
    padding: 0 15px;
  }

  .slick-list {
    margin: 0 -15px;
  }

  .slick-dots {
    bottom: -50px;

    li button:before {
      color: white;
    }

    li.slick-active button:before {
      color: var(--primary-color);
    }
  }

  .slick-prev, .slick-next {
    width: 40px;
    height: 40px;
    background: #141414;
    border: 1px solid #262626;
    border-radius: 50%;
    z-index: 1;

    &:hover {
      background: #1A1A1A;
    }

    &:before {
      color: var(--primary-color);
    }
  }

  .slick-prev {
    left: -50px;
  }

  .slick-next {
    right: -50px;
  }

  @media (max-width: 1300px) {
    .slick-prev {
      left: 10px;
    }

    .slick-next {
      right: 10px;
    }
  }
`;

const PropertyCard = styled.div`
  background: #141414;
  border: 1px solid #262626;
  border-radius: 16px;
  overflow: hidden;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

const PropertyImage = styled.div`
  height: 240px;
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
`;

const PropertyContent = styled.div`
  padding: 24px;
`;

const PropertyName = styled.h3`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 8px;
`;

const PropertyLocation = styled.p`
  color: #888;
  font-size: 14px;
  margin-bottom: 20px;
`;

const PropertyInfo = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  margin-bottom: 20px;
`;

const InfoItem = styled.div`
  background: #1A1A1A;
  padding: 16px;
  border-radius: 12px;
`;

const InfoLabel = styled.div`
  color: #888;
  font-size: 12px;
  margin-bottom: 4px;
`;

const InfoValue = styled.div`
  font-size: 16px;
  font-weight: 600;
`;

const ROIInfo = styled.div`
  background: var(--primary-color);
  padding: 16px;
  border-radius: 12px;
  text-align: center;
  margin-bottom: 20px;
`;

const ROIValue = styled.div`
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 4px;
`;

const ROIPeriod = styled.div`
  font-size: 14px;
  opacity: 0.8;
`;

const Features = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 20px;
`;

const Feature = styled.span`
  background: #1A1A1A;
  color: #888;
  padding: 6px 12px;
  border-radius: 20px;
  font-size: 12px;
`;

const Button = styled.button`
  width: 100%;
  background: transparent;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  padding: 14px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: var(--primary-color);
    color: white;
  }
`;

function InvestmentProperties() {
  const [selectedCountry, setSelectedCountry] = useState('all');

  const filteredProperties = properties.filter(property => 
    selectedCountry === 'all' || property.country === selectedCountry
  );

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    swipeToSlide: true,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: true
        }
      }
    ]
  };

  return (
    <Section>
      <Container>
        <Header>
          <Title>Инвестиционная недвижимость</Title>
        </Header>
        <CountrySelect>
          {countries.map(country => (
            <CountryButton
              key={country.id}
              $isActive={selectedCountry === country.id}
              onClick={() => setSelectedCountry(country.id)}
            >
              {country.name}
            </CountryButton>
          ))}
        </CountrySelect>
        <SliderWrapper>
          <Slider {...settings}>
            {filteredProperties.map(property => (
              <PropertyCard key={property.id}>
                <PropertyImage image={property.image} />
                <PropertyContent>
                  <PropertyName>{property.name}</PropertyName>
                  <PropertyLocation>{property.location}</PropertyLocation>
                  <ROIInfo>
                    <ROIValue>{property.roi.percent}%</ROIValue>
                    <ROIPeriod>Доходность за {property.roi.period}</ROIPeriod>
                  </ROIInfo>
                  <PropertyInfo>
                    <InfoItem>
                      <InfoLabel>Стоимость</InfoLabel>
                      <InfoValue>
                        {property.price.currency}{property.price.value.toLocaleString()}
                      </InfoValue>
                    </InfoItem>
                    <InfoItem>
                      <InfoLabel>Площадь</InfoLabel>
                      <InfoValue>
                        {property.area.value} {property.area.unit}
                      </InfoValue>
                    </InfoItem>
                  </PropertyInfo>
                  <Features>
                    {property.features.map((feature, index) => (
                      <Feature key={index}>{feature}</Feature>
                    ))}
                  </Features>
                  <Button>Подробнее об объекте</Button>
                </PropertyContent>
              </PropertyCard>
            ))}
          </Slider>
        </SliderWrapper>
      </Container>
    </Section>
  );
}

export default InvestmentProperties; 