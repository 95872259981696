import styled from 'styled-components';
import SEO from '../../components/SEO';
import HeroSearch from './components/HeroSearch';
import AboutUs from './components/AboutUs';
import ProspectiveCities from './components/ProspectiveCities';
import Partners from './components/Partners';
import PopularComplexes from './components/PopularComplexes';
import InvestmentProperties from './components/InvestmentProperties';
import Consultation from './components/Consultation';

const HomePage = styled.div`
  flex: 1;
`;

function Home() {
  return (
    <HomePage>
      <SEO 
        title="Главная"
        description="OverSeas - ваш надежный партнер в покупке недвижимости по всему миру. Инвестиции в недвижимость, новостройки, вторичное жилье в Дубае, Москве, Калининграде и других городах."
        keywords="недвижимость, инвестиции, новостройки, квартиры, дома, виллы, зарубежная недвижимость, Дубай, Москва, Калининград"
      />
      <HeroSearch />
      <AboutUs />
      <ProspectiveCities />
      <Partners />
      <PopularComplexes />
      <InvestmentProperties />
      <Consultation />
    </HomePage>
  );
}

export default Home; 