import { useState } from 'react';
import './styles.scss';

function EditListingModal({ listing, onClose, onSave }) {
  const [formData, setFormData] = useState({
    title: listing.title,
    price: listing.price,
    location: listing.location,
    description: listing.description || '',
    category: listing.category || '',
    type: listing.type || '',
    area: listing.area || '',
    rooms: listing.rooms || '',
    floor: listing.floor || '',
    totalFloors: listing.totalFloors || ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(formData);
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <div className="modal-header">
          <h2>Редактировать объявление</h2>
          <button className="close-button" onClick={onClose}>
            <i className="fas fa-times"></i>
          </button>
        </div>

        <form onSubmit={handleSubmit} className="edit-form">
          <div className="form-grid">
            <div className="form-group">
              <label>Название объявления</label>
              <input
                type="text"
                name="title"
                value={formData.title}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label>Цена</label>
              <input
                type="text"
                name="price"
                value={formData.price}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label>Местоположение</label>
              <input
                type="text"
                name="location"
                value={formData.location}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label>Категория</label>
              <select name="category" value={formData.category} onChange={handleChange}>
                <option value="">Выберите категорию</option>
                <option value="apartment">Квартира</option>
                <option value="house">Дом</option>
                <option value="commercial">Коммерческая недвижимость</option>
              </select>
            </div>

            <div className="form-group">
              <label>Тип объекта</label>
              <select name="type" value={formData.type} onChange={handleChange}>
                <option value="">Выберите тип</option>
                <option value="new">Новостройка</option>
                <option value="secondary">Вторичка</option>
              </select>
            </div>

            <div className="form-group">
              <label>Площадь, м²</label>
              <input
                type="number"
                name="area"
                value={formData.area}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label>Количество комнат</label>
              <input
                type="number"
                name="rooms"
                value={formData.rooms}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label>Этаж</label>
              <div className="floor-inputs">
                <input
                  type="number"
                  name="floor"
                  value={formData.floor}
                  onChange={handleChange}
                  placeholder="Этаж"
                />
                <span>из</span>
                <input
                  type="number"
                  name="totalFloors"
                  value={formData.totalFloors}
                  onChange={handleChange}
                  placeholder="Всего"
                />
              </div>
            </div>
          </div>

          <div className="form-group full-width">
            <label>Описание</label>
            <textarea
              name="description"
              value={formData.description}
              onChange={handleChange}
              rows="6"
            ></textarea>
          </div>

          <div className="form-actions">
            <button type="button" className="cancel-button" onClick={onClose}>
              Отменить
            </button>
            <button type="submit" className="save-button">
              Сохранить изменения
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default EditListingModal; 