import { useState } from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const countries = [
  { id: 'all', name: 'Все страны' },
  { id: 'uae', name: 'ОАЭ', cities: ['Dubai', 'Palm Jumeirah'] },
  { id: 'russia', name: 'Россия', cities: ['Москва', 'Калининград', 'Светлогорск'] },
  { id: 'indonesia', name: 'Индонезия', cities: ['Бали', 'Canggu'] },
  { id: 'singapore', name: 'Сингапур', cities: ['Marina Bay'] }
];

// Расширенный список комплексов
const complexes = [
  {
    id: 1,
    name: 'Dubai Creek Harbor',
    country: 'uae',
    location: 'Dubai Creek Harbor, Dubai',
    developer: 'Emaar Properties',
    image: 'https://images.unsplash.com/photo-1512453979798-5ea266f8880c?ixlib=rb-4.0.3',
    price: {
      from: 250000,
      currency: '$'
    },
    completion: '2024 Q4',
    features: ['Вид на море', 'Рассрочка', 'Готовая отделка'],
    type: 'Апартаменты',
    area: {
      from: 45,
      to: 200,
      unit: 'м²'
    },
    status: 'Строится',
    roi: '8% годовых'
  },
  {
    id: 2,
    name: 'Москва Сити - Capital Towers',
    country: 'russia',
    location: 'Пресненская набережная, Москва',
    developer: 'Capital Group',
    image: 'https://images.unsplash.com/photo-1513326738677-b964603b136d?ixlib=rb-4.0.3',
    price: {
      from: 25000000,
      currency: '₽'
    },
    completion: '2023 Q4',
    features: ['Премиум класс', 'Вид на город', 'Консьерж'],
    type: 'Апартаменты',
    area: {
      from: 60,
      to: 300,
      unit: 'м²'
    },
    status: 'Готов',
    roi: '6% годовых'
  },
  {
    id: 3,
    name: 'Palm Jumeirah Residences',
    country: 'uae',
    location: 'Palm Jumeirah, Dubai',
    developer: 'Nakheel',
    image: 'https://images.unsplash.com/photo-1582268611958-ebfd161ef9cf?ixlib=rb-4.0.3',
    price: {
      from: 800000,
      currency: '$'
    },
    completion: '2024 Q2',
    features: ['Частный пляж', 'Премиум отделка', 'Панорамные окна'],
    type: 'Виллы',
    area: {
      from: 250,
      to: 1000,
      unit: 'м²'
    },
    status: 'Строится',
    roi: '7% годовых'
  },
  {
    id: 4,
    name: 'Светлогорск Резиденции',
    country: 'russia',
    location: 'Светлогорск, Калининград',
    developer: 'Мегаполис',
    image: 'https://images.unsplash.com/photo-1545324418-cc1a3fa10c00?ixlib=rb-4.0.3',
    price: {
      from: 8000000,
      currency: '₽'
    },
    completion: '2024 Q1',
    features: ['Вид на море', 'Закрытая территория', 'Подземный паркинг'],
    type: 'Апартаменты',
    area: {
      from: 40,
      to: 150,
      unit: 'м²'
    },
    status: 'Строится',
    roi: '5% годовых'
  },
  {
    id: 5,
    name: 'Bali Beach Villas',
    country: 'indonesia',
    location: 'Canggu, Bali',
    developer: 'Paradise Group',
    image: 'https://images.unsplash.com/photo-1505321099522-6580c6016c46?ixlib=rb-4.0.3',
    price: {
      from: 300000,
      currency: '$'
    },
    completion: '2023 Q4',
    features: ['Бассейн', 'Тропический сад', 'Управление'],
    type: 'Виллы',
    area: {
      from: 150,
      to: 400,
      unit: 'м²'
    },
    status: 'Готов',
    roi: '9% годовых'
  },
  {
    id: 6,
    name: 'Singapore Sky Gardens',
    country: 'singapore',
    location: 'Marina Bay, Singapore',
    developer: 'CapitaLand',
    image: 'https://images.unsplash.com/photo-1533395427226-c8e07a0c9f67?ixlib=rb-4.0.3',
    price: {
      from: 1200000,
      currency: '$'
    },
    completion: '2025 Q1',
    features: ['Sky Pool', 'Умный дом', 'Консьерж'],
    type: 'Апартаменты',
    area: {
      from: 70,
      to: 250,
      unit: 'м²'
    },
    status: 'Строится',
    roi: '5.5% годовых'
  }
];

const Section = styled.section`
  padding: 80px 0;
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
`;

const Title = styled.h2`
  font-size: 36px;
  font-weight: 700;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));
  gap: 30px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const ComplexCard = styled.div`
  background: #141414;
  border: 1px solid #262626;
  border-radius: 16px;
  overflow: hidden;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

const ComplexImage = styled.div`
  height: 240px;
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
`;

const ComplexContent = styled.div`
  padding: 24px;
`;

const ComplexName = styled.h3`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 8px;
`;

const ComplexLocation = styled.p`
  color: #888;
  font-size: 14px;
  margin-bottom: 20px;
`;

const ComplexInfo = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-bottom: 20px;
`;

const InfoItem = styled.div`
  background: #1A1A1A;
  padding: 16px;
  border-radius: 12px;
`;

const InfoLabel = styled.div`
  color: #888;
  font-size: 12px;
  margin-bottom: 4px;
`;

const InfoValue = styled.div`
  font-size: 16px;
  font-weight: 600;
`;

const Features = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 20px;
`;

const Feature = styled.span`
  background: #1A1A1A;
  color: #888;
  padding: 6px 12px;
  border-radius: 20px;
  font-size: 12px;
`;

const Button = styled.button`
  width: 100%;
  background: transparent;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  padding: 14px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: var(--primary-color);
    color: white;
  }
`;

const SliderWrapper = styled.div`
  .slick-slide {
    padding: 0 15px;
  }

  .slick-list {
    margin: 0 -15px;
  }

  .slick-dots {
    bottom: -50px;

    li button:before {
      color: white;
    }

    li.slick-active button:before {
      color: var(--primary-color);
    }
  }

  .slick-prev, .slick-next {
    width: 40px;
    height: 40px;
    background: #141414;
    border: 1px solid #262626;
    border-radius: 50%;
    z-index: 1;

    &:hover {
      background: #1A1A1A;
    }

    &:before {
      color: var(--primary-color);
    }
  }

  .slick-prev {
    left: -50px;
  }

  .slick-next {
    right: -50px;
  }

  @media (max-width: 1300px) {
    .slick-prev {
      left: 10px;
    }

    .slick-next {
      right: 10px;
    }
  }
`;

const CountrySelect = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 40px;
  flex-wrap: wrap;
  justify-content: center;

  @media (max-width: 768px) {
    gap: 10px;
    margin-bottom: 30px;
  }

  @media (max-width: 480px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
  }
`;

const CountryButton = styled.button`
  background: ${props => props.$isActive ? '#141414' : 'transparent'};
  border: 1px solid ${props => props.$isActive ? '#262626' : 'transparent'};
  color: ${props => props.$isActive ? 'white' : '#888'};
  padding: 14px 24px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  white-space: nowrap;

  @media (max-width: 768px) {
    padding: 12px 20px;
    font-size: 14px;
  }

  @media (max-width: 480px) {
    padding: 10px 16px;
    width: 100%;
    white-space: normal;
    text-align: center;
  }

  &:hover {
    color: white;
    background: #141414;
    border-color: #262626;
  }
`;

function PopularComplexes() {
  const [selectedCountry, setSelectedCountry] = useState('all');

  const filteredComplexes = complexes.filter(complex => 
    selectedCountry === 'all' || complex.country === selectedCountry
  );

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <Section>
      <Container>
        <Header>
          <Title>Популярные жилые комплексы</Title>
        </Header>
        <CountrySelect>
          {countries.map(country => (
            <CountryButton
              key={country.id}
              $isActive={selectedCountry === country.id}
              onClick={() => setSelectedCountry(country.id)}
            >
              {country.name}
            </CountryButton>
          ))}
        </CountrySelect>
        <SliderWrapper>
          <Slider {...settings}>
            {filteredComplexes.map(complex => (
              <ComplexCard key={complex.id}>
                <ComplexImage image={complex.image} />
                <ComplexContent>
                  <ComplexName>{complex.name}</ComplexName>
                  <ComplexLocation>{complex.location}</ComplexLocation>
                  <ComplexInfo>
                    <InfoItem>
                      <InfoLabel>Стоимость от</InfoLabel>
                      <InfoValue>
                        {complex.price.currency}{complex.price.from.toLocaleString()}
                      </InfoValue>
                    </InfoItem>
                    <InfoItem>
                      <InfoLabel>Площадь</InfoLabel>
                      <InfoValue>
                        {complex.area.from} - {complex.area.to} {complex.area.unit}
                      </InfoValue>
                    </InfoItem>
                    <InfoItem>
                      <InfoLabel>Готовность</InfoLabel>
                      <InfoValue>{complex.completion}</InfoValue>
                    </InfoItem>
                    <InfoItem>
                      <InfoLabel>Доходность</InfoLabel>
                      <InfoValue>{complex.roi}</InfoValue>
                    </InfoItem>
                  </ComplexInfo>
                  <Features>
                    {complex.features.map((feature, index) => (
                      <Feature key={index}>{feature}</Feature>
                    ))}
                  </Features>
                  <Button>Подробнее о комплексе</Button>
                </ComplexContent>
              </ComplexCard>
            ))}
          </Slider>
        </SliderWrapper>
      </Container>
    </Section>
  );
}

export default PopularComplexes; 