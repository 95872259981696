import './styles.scss';
import { useState } from 'react';
import EditListingModal from '../EditListingModal';
import ListingStatsModal from '../ListingStatsModal';

const mockListings = [
  {
    id: 1,
    title: 'Квартира в центре города',
    price: '12 500 000 ₽',
    location: 'Москва, ЦАО',
    image: 'https://via.placeholder.com/300x200',
    views: 245,
    favorites: 12,
    status: 'active',
    promoted: true,
    date: '2024-03-15'
  },
  // Добавьте больше мок-данных...
];

function ListingsGrid({ activeTab, searchQuery, filters }) {
  const [editingListing, setEditingListing] = useState(null);
  const [viewingStats, setViewingStats] = useState(null);
  const listings = mockListings; // В реальном приложении здесь будет фильтрация

  return (
    <div className="listings-grid">
      {listings.length > 0 ? (
        <>
          <div className="grid">
            {listings.map(listing => (
              <div key={listing.id} className="listing-card">
                <div className="listing-image">
                  <img src={listing.image} alt={listing.title} />
                  {listing.promoted && (
                    <div className="promoted-badge">
                      <i className="fas fa-star"></i> Продвигается
                    </div>
                  )}
                </div>
                <div className="listing-content">
                  <h3 className="listing-title">{listing.title}</h3>
                  <div className="listing-price">{listing.price}</div>
                  <div className="listing-location">
                    <i className="fas fa-map-marker-alt"></i>
                    {listing.location}
                  </div>
                  <div className="listing-stats">
                    <div className="stat">
                      <i className="fas fa-eye"></i>
                      {listing.views}
                    </div>
                    <div className="stat">
                      <i className="fas fa-heart"></i>
                      {listing.favorites}
                    </div>
                    <div className="stat">
                      <i className="fas fa-calendar"></i>
                      {new Date(listing.date).toLocaleDateString()}
                    </div>
                  </div>
                </div>
                <div className="listing-actions">
                  <button 
                    className="action-button"
                    onClick={() => setEditingListing(listing)}
                  >
                    <i className="fas fa-edit"></i>
                    Редактировать
                  </button>
                  <button 
                    className="action-button"
                    onClick={() => setViewingStats(listing)}
                  >
                    <i className="fas fa-chart-line"></i>
                    Статистика
                  </button>
                  <button className="action-button delete">
                    <i className="fas fa-trash"></i>
                  </button>
                </div>
              </div>
            ))}
          </div>

          {editingListing && (
            <EditListingModal
              listing={editingListing}
              onClose={() => setEditingListing(null)}
              onSave={(updatedData) => {
                console.log('Saving:', updatedData);
                setEditingListing(null);
              }}
            />
          )}

          {viewingStats && (
            <ListingStatsModal
              listing={viewingStats}
              onClose={() => setViewingStats(null)}
            />
          )}
        </>
      ) : (
        <div className="empty-state">
          <div className="empty-icon">
            <i className="fas fa-search"></i>
          </div>
          <h3>Ничего не нашлось</h3>
          <p>
            Возможно, в запрос закралась ошибка<br />
            или объявление переехало в другую вкладку.
          </p>
          <button className="reset-button">
            <i className="fas fa-redo"></i>
            Сбросить фильтры
          </button>
        </div>
      )}
    </div>
  );
}

export default ListingsGrid; 