import { useState } from 'react';
import './styles.scss';

function ListingStatsModal({ listing, onClose }) {
  const [period, setPeriod] = useState('week');

  // Моковые данные для статистики
  const stats = {
    views: {
      total: 1245,
      trend: +15,
      chart: [120, 145, 132, 168, 189, 156, 178]
    },
    favorites: {
      total: 48,
      trend: +5,
      chart: [8, 12, 10, 15, 18, 14, 16]
    },
    contacts: {
      total: 36,
      trend: -2,
      chart: [6, 8, 7, 9, 12, 8, 10]
    },
    conversion: {
      rate: '2.89%',
      trend: +0.4
    }
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <div className="modal-header">
          <div className="header-content">
            <h2>Статистика объявления</h2>
            <p className="listing-title">{listing.title}</p>
          </div>
          <button className="close-button" onClick={onClose}>
            <i className="fas fa-times"></i>
          </button>
        </div>

        <div className="stats-content">
          <div className="period-selector">
            <button 
              className={period === 'week' ? 'active' : ''} 
              onClick={() => setPeriod('week')}
            >
              Неделя
            </button>
            <button 
              className={period === 'month' ? 'active' : ''} 
              onClick={() => setPeriod('month')}
            >
              Месяц
            </button>
            <button 
              className={period === 'year' ? 'active' : ''} 
              onClick={() => setPeriod('year')}
            >
              Год
            </button>
          </div>

          <div className="stats-grid">
            <div className="stat-card">
              <div className="stat-header">
                <h3>Просмотры</h3>
                <div className={`trend ${stats.views.trend > 0 ? 'positive' : 'negative'}`}>
                  <i className={`fas fa-arrow-${stats.views.trend > 0 ? 'up' : 'down'}`}></i>
                  {Math.abs(stats.views.trend)}%
                </div>
              </div>
              <div className="stat-value">{stats.views.total}</div>
              <div className="stat-chart">
                {/* Здесь можно добавить график */}
              </div>
            </div>

            <div className="stat-card">
              <div className="stat-header">
                <h3>В избранном</h3>
                <div className={`trend ${stats.favorites.trend > 0 ? 'positive' : 'negative'}`}>
                  <i className={`fas fa-arrow-${stats.favorites.trend > 0 ? 'up' : 'down'}`}></i>
                  {Math.abs(stats.favorites.trend)}%
                </div>
              </div>
              <div className="stat-value">{stats.favorites.total}</div>
              <div className="stat-chart">
                {/* Здесь можно добавить график */}
              </div>
            </div>

            <div className="stat-card">
              <div className="stat-header">
                <h3>Контакты</h3>
                <div className={`trend ${stats.contacts.trend > 0 ? 'positive' : 'negative'}`}>
                  <i className={`fas fa-arrow-${stats.contacts.trend > 0 ? 'up' : 'down'}`}></i>
                  {Math.abs(stats.contacts.trend)}%
                </div>
              </div>
              <div className="stat-value">{stats.contacts.total}</div>
              <div className="stat-chart">
                {/* Здесь можно добавить график */}
              </div>
            </div>

            <div className="stat-card">
              <div className="stat-header">
                <h3>Конверсия</h3>
                <div className={`trend ${stats.conversion.trend > 0 ? 'positive' : 'negative'}`}>
                  <i className={`fas fa-arrow-${stats.conversion.trend > 0 ? 'up' : 'down'}`}></i>
                  {Math.abs(stats.conversion.trend)}%
                </div>
              </div>
              <div className="stat-value">{stats.conversion.rate}</div>
              <div className="stat-chart">
                {/* Здесь можно добавить график */}
              </div>
            </div>
          </div>

          <div className="detailed-stats">
            <h3>Детальная статистика</h3>
            <table className="stats-table">
              <thead>
                <tr>
                  <th>Дата</th>
                  <th>Просмотры</th>
                  <th>В избранном</th>
                  <th>Контакты</th>
                  <th>Конверсия</th>
                </tr>
              </thead>
              <tbody>
                {[...Array(7)].map((_, index) => (
                  <tr key={index}>
                    <td>{new Date(Date.now() - index * 86400000).toLocaleDateString()}</td>
                    <td>{stats.views.chart[index]}</td>
                    <td>{stats.favorites.chart[index]}</td>
                    <td>{stats.contacts.chart[index]}</td>
                    <td>{((stats.contacts.chart[index] / stats.views.chart[index]) * 100).toFixed(2)}%</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ListingStatsModal; 