import styled from 'styled-components';

const Section = styled.section`
  padding: 80px 0;
  background: #111;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, rgba(108, 93, 211, 0.1), transparent);
    pointer-events: none;
  }
`;

const BackgroundImage = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  background-image: url('https://images.unsplash.com/photo-1582268611958-ebfd161ef9cf?ixlib=rb-4.0.3');
  background-size: cover;
  background-position: center;
  opacity: 0.1;
  mask-image: linear-gradient(to left, rgba(0,0,0,1), rgba(0,0,0,0));
  -webkit-mask-image: linear-gradient(to left, rgba(0,0,0,1), rgba(0,0,0,0));

  @media (max-width: 768px) {
    width: 100%;
    opacity: 0.05;
  }
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  position: relative;
  z-index: 1;
`;

const Content = styled.div`
  max-width: 600px;
  background: #141414;
  border: 1px solid #262626;
  border-radius: 24px;
  padding: 40px;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    padding: 30px;
  }
`;

const Title = styled.h2`
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 20px;
  background: linear-gradient(45deg, #fff, #6C5DD3);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media (max-width: 768px) {
    font-size: 28px;
  }
`;

const Description = styled.p`
  color: #888;
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    font-size: 16px;
    margin-bottom: 30px;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const InputGroup = styled.div`
  position: relative;
`;

const Input = styled.input`
  width: 100%;
  padding: 16px 20px;
  background: #1A1A1A;
  border: 1px solid #262626;
  border-radius: 12px;
  color: white;
  font-size: 16px;
  transition: all 0.2s ease;

  &::placeholder {
    color: #666;
  }

  &:focus {
    outline: none;
    border-color: var(--primary-color);
    background: #1D1D1D;
  }
`;

const Button = styled.button`
  width: 100%;
  padding: 16px;
  background: var(--primary-color);
  border: none;
  border-radius: 12px;
  color: white;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      transparent,
      rgba(255, 255, 255, 0.2),
      transparent
    );
    transition: 0.5s;
  }

  &:hover::before {
    left: 100%;
  }

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(108, 93, 211, 0.3);
  }

  &:active {
    transform: translateY(0);
  }
`;

const PrivacyText = styled.p`
  color: #666;
  font-size: 12px;
  text-align: center;
  margin-top: 20px;
`;

function Consultation() {
  return (
    <Section>
      <BackgroundImage />
      <Container>
        <Content>
          <Title>Провести консультацию по недвижимости</Title>
          <Description>
            Нужна консультация по покупке/продаже недвижимости в вашем городе?
            Оставьте свои контакты и мы свяжемся с вами в ближайшее время.
          </Description>
          <Form>
            <InputGroup>
              <Input 
                type="text" 
                placeholder="Ваше имя" 
              />
            </InputGroup>
            <InputGroup>
              <Input 
                type="tel" 
                placeholder="Телефон" 
              />
            </InputGroup>
            <InputGroup>
              <Input 
                type="email" 
                placeholder="E-mail" 
              />
            </InputGroup>
            <Button type="submit">Оставить заявку</Button>
            <PrivacyText>
              Нажимая на кнопку, вы даете согласие на обработку персональных данных
            </PrivacyText>
          </Form>
        </Content>
      </Container>
    </Section>
  );
}

export default Consultation; 